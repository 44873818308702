.heading-1 {
  @apply text-4xl;
  @apply md:text-8xl;
  @apply lg:text-9xl;
}

.heading-2 {
  @apply text-xl;
  @apply md:text-2xl;
  @apply lg:text-4xl;
}

.heading-3 {
  @apply text-lg;
  @apply md:text-xl;
}

.body-1 {
  @apply text-base;
  @apply md:text-lg;
}

.body-2 {
  @apply text-sm;
  @apply md:text-base;
}

.body-3 {
  @apply text-xs;
  @apply md:text-sm;
}

.logo {
  @apply lg:text-xl;
}
