.number-card-content {
    position: relative;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    padding: 1.25rem;
    justify-content: space-between;
    background-position: center;
    background-size: cover;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
    height: 100%;
    overflow: hidden;
}

/* Base background color for cards without images */
.number-card-container.no-image .number-card-content {
    background-color: var(--color-background, #121212);
}

/* Primary grid pattern */
.number-card-container.no-image .number-card-content::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: radial-gradient(circle at center, rgba(255, 255, 255, 0.03) 1px, transparent 1px);
    background-size: 4px 4px;
    z-index: 0;
}

/* Secondary larger dot pattern for added dimension */
.number-card-container.no-image .number-card-content::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: radial-gradient(circle at center, rgba(58, 121, 237, 0.04) 1px, transparent 1px),
        radial-gradient(circle at center, rgba(58, 186, 237, 0.04) 1px, transparent 1px);
    background-size:
        16px 16px,
        24px 24px;
    background-position:
        0 0,
        8px 8px;
    z-index: 0;
}

.number-card-value,
.number-card-text {
    position: relative;
    z-index: 1;
}
