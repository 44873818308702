.shadow-smooth {
  box-shadow:
    0px 0px 0.8px rgba(0, 0, 0, 0.008),
    0px 0px 2.7px rgba(0, 0, 0, 0.012),
    0px 0px 12px rgba(0, 0, 0, 0.06);
}

.full-width-section {
  @apply -ml-5;
  @apply md:-ml-20;
  @apply 2xl:-ml-40;
  @apply w-screen;
}

.card-highlight {
  @apply border-smooth;
  @apply shadow-sm;
  @apply rounded-2xl;
}

.border-accent-hover {
  @apply border-smooth;
  &:hover {
    @apply border-accent/40;
  }
}

.border-smooth {
  @apply border;
  @apply border-accent/20;
}

.section-bg {
  background: linear-gradient(304deg, rgb(33 33 34 / 100%) 0%, rgb(27 28 37 / 100%) 100%);
  @apply card-highlight;

  &--light {
    background: linear-gradient(304deg, rgb(33 33 34 / 100%) 0%, rgb(27 28 37 / 100%) 100%);
  }
}
