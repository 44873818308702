@import "./colors";
@import "./fonts";
@import "./globals";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --main: 255 255 255;
    --background: 0 0 0;
    --accent: 0 232 235;
    --secondary: 255 113 48;
    --red: 207 78 55;
    --grey: 38 38 38;
    --darkGrey: 68 68 68;
    --lightGrey: 149 149 149;
    --stroke: 35 35 35;
    --white: 255 255 255;
  }
}

[data-theme="light"] {
  --main: 12 12 12;
  --background: 255 255 255;
}

.calendly-overlay {
  background-color: rgba(0, 0, 0, 0.6) !important;
  backdrop-filter: blur(4px);
}

.calendly-popup {
  max-height: 690px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  @apply text-main;
  box-sizing: border-box;
  font-family:
    "Sora",
    -apple-system,
    BlinkMacSystemFont,
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}

*::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

*::-webkit-scrollbar-thumb {
  @apply bg-darkGrey;
  @apply rounded-full;
}

.filter-green {
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
}
